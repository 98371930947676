@import '../../variables';

#project-hero {
    padding-top: 12vw;
    position: relative;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 12vw);
    justify-content: flex-end;

    .text {
        display: flex;
        align-items: center;
        @media (max-width: $mobile_version) {
            align-items: flex-start;
            flex-direction: column;
            margin-top: 40vh;
        }
    }

    h1 {
        flex-grow: 1;
        font-size: $text_size_title;
        line-height: $text_size_title * 1.1;
        letter-spacing: -1px;
        @media (max-width: $mobile_version) {
            font-size: $text_size_title_MOB;
            line-height: $text_size_title_MOB * 1.4;
        }
    }

    p {
        width: 35%;
        @media (max-width: $mobile_version) {
            width: unset;
        }
    }

    .image {
        width: 100%;
        margin-top: 4vh;
        height: 63vh;
        position: relative;
        @media (max-width: $mobile_version) {
            width: 100vw;
            height: 50vh;
        }
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .scrollbutton {
        position: absolute;
        right: 12vh;
        bottom: 6vh;
        z-index: 10;
        @media (max-width: $mobile_version) {
            right: unset;
            left: 8vw;
        }
    }

    @media (max-width: $mobile_version) {
        h2 {
            margin-top: 10vw;
        }
    }
}
