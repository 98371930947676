@import '../../variables';

.services-section {
    h2 {
        font-size: $text_size_large;
        line-height: $text_size_large * 1.4;
        letter-spacing: -1px;
        @media (max-width: $mobile_version) {
            font-size: $text_size_large_MOB;
            line-height: $text_size_large_MOB * 1.4;
        }
    }

    position: relative;

    .background {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    #services,
    #projects {
        padding-top: 5vw;
        padding-bottom: 5vw;

        p {
            margin-top: 1vw;
        }

        ul {
            gap: 10vw;
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            flex-direction: column;
            @media (max-width: $mobile_version) {
                gap: 20vw;
                margin-top: 10vw;
            }

            li {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                gap: 4vw;
                position: relative;

                @media (max-width: $mobile_version) {
                    flex-direction: column !important;
                }

                .line {
                    position: absolute;
                    bottom: 2.2vw;
                    left: -2vw;
                    width: 8vw;
                    height: 2px;
                    background: white;
                    z-index: 10;
                    @media (max-width: $mobile_version) {
                        bottom: unset;
                        top: 4vw;
                    }
                }
                .text {
                    width: 55%;
                    margin-top: 2vw;

                    @media (max-width: $mobile_version) {
                        width: 100%;
                        margin-top: 0;
                    }
                }
                .image {
                    height: 22vw;
                    opacity: 0.9;
                    @media (max-width: $mobile_version) {
                        height: 35vw;
                        width: 100%;
                    }
                }
                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
                p {
                    white-space: pre-wrap;
                }
                .btn {
                    margin-top: 1vw;
                    @media (max-width: $mobile_version) {
                        margin-top: 3vw;
                    }
                }

                &.right {
                    flex-direction: row-reverse;
                    .line {
                        right: -2vw;
                        left: unset;
                    }
                }
            }
        }
    }

    #projects ul {
        gap: 0;
        flex-wrap: wrap;
        flex-direction: row;
        @media (max-width: $mobile_version) {
            flex-direction: column;
        }

        li {
            width: 30%;
            display: block;
            cursor: pointer;
            margin-bottom: 5vw;
            @media (max-width: $mobile_version) {
                margin-bottom: 10vw;
                margin-top: 5vw;
                width: 100%;
            }

            .text {
                width: 80%;
                @media (max-width: $mobile_version) {
                    margin-top: 4vw;
                }
            }

            .image {
                height: 15vw;
                @media (max-width: $mobile_version) {
                    height: 40vw;
                }
            }

            &:hover img {
                opacity: 0.6;
            }
        }

        .btn {
            margin: 0 auto;
        }
    }
}
