@import 'variables';

* {
    margin: 0;
    margin-block: 0;
}

::-moz-selection {
    /* Code for Firefox */
    background: $color_dawn;
}

::selection {
    background: $color_dawn;
}

ul {
    padding-inline-start: 0;
    list-style: none;
}

a {
    color: inherit;
    cursor: pointer;
    text-decoration: inherit;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    &:hover {
        color: $color_jet;
    }
}

span {
    color: $color_brand;
}

img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
    will-change: transform;
}

::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}

::-webkit-scrollbar-thumb {
    background: #e5e5e5;
    -webkit-box-shadow: inset 0 0 6px #e5e5e5;
    box-shadow: inset 0 0 6px #e5e5e5;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}
::-webkit-scrollbar-thumb:window-inactive {
    background: #101010;
}
::-webkit-scrollbar-thumb:hover {
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $color_darker;
    color: $color_light;
    font-weight: normal;
    font-family: $font_main;
    letter-spacing: $text_spacing_none;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.scroll-html {
    width: 100vw;
    overflow: hidden;
}

.padding {
    padding-left: $padding_main;
    padding-right: $padding_main;
    @media (max-width: $mobile_version) {
        padding-right: $padding_main_MOB;
        padding-left: $padding_main_MOB;
    }
}

body {
    cursor: default;
}

h1,
h2,
h3,
h4,
h5,
p,
div {
    cursor: inherit;
}

h1 {
    font-size: $text_size_heading;
    line-height: $text_size_heading * 1.1;
    letter-spacing: -1px;
    @media (max-width: $mobile_version) {
        font-size: $text_size_heading_MOB;
        line-height: $text_size_heading_MOB;
    }
}

h2 {
    font-size: $text_size_title;
    line-height: $text_size_title * 1.1;
    letter-spacing: -1px;
    @media (max-width: $mobile_version) {
        font-size: $text_size_title_MOB;
        line-height: $text_size_title_MOB * 1.4;
    }
}

h3 {
    font-size: $text_size_large;
    line-height: $text_size_large * 1.4;
    letter-spacing: -1px;
    @media (max-width: $mobile_version) {
        font-size: $text_size_large_MOB;
        line-height: $text_size_large_MOB * 1.4;
    }
}

h4 {
    font-size: $text_size_big;
    line-height: $text_size_big * 1.4;
    letter-spacing: -1px;
    font-weight: 600;
    @media (max-width: $mobile_version) {
        font-size: $text_size_big_MOB;
        line-height: $text_size_big_MOB * 1.4;
    }
}

h5 {
    font-size: $text_size_med;
    line-height: $text_size_med * 1.4;
    letter-spacing: 0;
    font-weight: 600;
    @media (max-width: $mobile_version) {
        font-size: $text_size_med_MOB;
        line-height: $text_size_med_MOB * 1.4;
    }
}

p {
    font-size: $text_size_normal;
    line-height: $text_size_normal * 1.4;
    &.tiny {
        letter-spacing: 0;
        font-size: $text_size_tiny;
        line-height: $text_size_tiny * 1.4;
    }
    @media (max-width: $mobile_version) {
        font-size: $text_size_normal_MOB;
        line-height: $text_size_normal_MOB * 1.4;
        &.tiny {
            font-size: $text_size_tiny_MOB;
            line-height: $text_size_tiny_MOB * 1.4;
        }
    }
}

input,
textarea {
    height: 1.8vw;
    min-height: 1.8vw;
    padding: 0.2vw 0.7vw;
    font-size: 0.7vw;
    line-height: 0.8;
    border: solid 2px $color_grey;
    border-radius: 0.3vw;
    width: calc(100% - 1.4vw - 4px);
    color: $color_dawn;
    font-family: $font_main;
    letter-spacing: 1px;
    @media (max-width: $mobile_version) {
        letter-spacing: 0px;
        height: 5.8vw;
        width: calc(100% - 4vw - 4px);
        min-height: 5.8vw;
        padding: 0.4vw 2vw;
        font-size: 2vw;
        line-height: 2;
    }

    &:focus {
        border: solid 2px $color_grey;
        color: $color_black;
        border-color: $color_black;
    }

    &::placeholder {
        color: $color_grey;
    }
}

textarea {
    resize: vertical;
    padding-top: 0.8vw;
    min-height: 8vw;

    &::-webkit-scrollbar {
        width: 1vw;
        height: 1vw;
    }
}

.pre {
    white-space: pre;
}

.txtal-r {
    text-align: right;
}

.txtal-c {
    text-align: center;
}

.relative {
    position: relative;
}

.btn {
    cursor: pointer;
    white-space: nowrap;
    font-size: $text_size_tiny;
    letter-spacing: $text_spacing_zero;
    padding: 0.7vw 4vw;
    color: $color_dark;
    font-weight: 300;
    will-change: background-color;
    border: 1px solid $color_dawn;
    float: left;
    &:hover {
        color: $color_white;
        background-color: $color_dawn;
    }
    @media (max-width: $mobile_version) {
        font-size: $text_size_tiny_MOB;
        padding: 2vw 16vw;
        &.full {
            width: 100%;
            padding: 0.7vw 0;
            text-align: center;
        }
    }

    &.light-btn {
        color: $color_white;
        border: 1px solid $color_white;
        background-color: transparent;
        &:hover {
            color: $color_dawn;
            background-color: $color_white;
        }
    }

    &.dark-set-btn {
        color: $color_white;
        background-color: $color_dawn;
        &:hover {
            background-color: $color_darker;
            border: 1px solid $color_darker;
        }
    }
}

iframe {
    width: 100vw;
}

.ease {
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.ease-img {
    -webkit-transition: opacity 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    -moz-transition: opacity 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    -ms-transition: opacity 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    -o-transition: opacity 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition: opacity 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

// .ease-works {
//     -webkit-transition: all 0.3s ease-in-out;
//     -moz-transition: all 0.3s ease-in-out;
//     -ms-transition: all 0.3s ease-in-out;
//     -o-transition: all 0.3s ease-in-out;
//     transition: all 0.3s ease-in-out;
// }

.ease-carousel {
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

.mobile {
    display: none;
}

.pc {
    display: block;
}

@media (max-width: $mobile_version) {
    .pc {
        display: none;
    }

    .mobile {
        display: block;
    }
}
