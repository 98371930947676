@import '../../variables';

.partners-section {
    #partners {
        padding-top: 8vw;
        padding-bottom: 8vw;
        @media (max-width: $mobile_version) {
            padding-top: 16vw;
            padding-bottom: 16vw;
        }

        h3 {
            text-align: center;
        }

        .partners {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: stretch;
            width: 70vw;
            margin: 0 auto;
            gap: 10vw;
            margin-top: 2vw;
            @media (max-width: $mobile_version) {
                flex-wrap: wrap;
                justify-content: center;
            }

            .partner {
                opacity: 0.6;
                max-width: 12vw;

                @media (max-width: $mobile_version) {
                    max-width: 30vw;
                }
                &:hover {
                    opacity: 0.9;
                }
                img {
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
