@import '../variables';

.footer-section {
    padding-top: 4vw;
    padding-bottom: 4vw;
    img {
        height: 2vw;
    }
    .text {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    p {
        font-size: $text_size_tiny;
        line-height: $text_size_tiny * 1.4;
        font-weight: 200;
        @media (max-width: $mobile_version) {
            font-size: $text_size_tiny_MOB;
            line-height: $text_size_tiny_MOB;
        }
    }
}
