@import '../../variables';

.carousel-section {
    position: relative;

    .background {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    #carousel {
        padding-top: 10vw;
        padding-bottom: 8vw;

        p {
            margin-top: 1vw;
            width: 55vw;
            @media (max-width: $mobile_version) {
                width: 100%;
            }
        }

        ul {
            align-items: stretch;
            display: flex;
            flex-wrap: wrap;
            gap: 1vw;
            justify-content: flex-start;
            margin-top: 3vw;
            @media (max-width: $mobile_version) {
                flex-direction: column;
                height: unset;
            }

            a {
                width: 32.5%;
                @media (max-width: $mobile_version) {
                    width: 100%;
                    margin-top: 4vw;
                    margin-bottom: 4vw;
                }
                &:hover img {
                    opacity: 0.6;
                }
                li {
                    height: 100%;
                    width: 100%;
                }
                img {
                    cursor: pointer;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
                p {
                    margin-top: 0.5vw;
                    width: 100%;
                    font-weight: 700;
                    color: $color_jet;
                }
            }
        }
    }
}
