@import '../../variables';

.about-section {
    img {
        position: absolute;
        z-index: -1;
        width: 75vw;
        left: -9vw;
        height: auto;
    }

    #about {
        padding-top: 20vw;
        padding-bottom: 20vw;

        p {
            margin-top: 2vw;
            width: 30vw;
            @media (max-width: $mobile_version) {
                width: 100%;
            }
        }

        .text {
            display: flex;
            justify-content: end;
            .right {
                padding-right: 7vw;
            }
        }
    }
}
