@import '../../variables';

.jobs-section {
    background-color: $color_darkest;

    .text {
        padding: 10vw;
        text-align: center;

        @media (max-width: $mobile_version) {
            padding: 20vw 0;
        }

        p {
            margin-top: 1.5vw;
            color: $color_milk;
            span {
                color: white;
            }
        }
    }

    h2 {
        font-size: 2.6vw;
        line-height: 3.2vw;
        letter-spacing: 1px;
        @media (max-width: $mobile_version) {
            font-size: 7vw;
            line-height: 9vw;
        }
        margin-bottom: 3vw;
    }

    @media (max-width: $mobile_version) {
    }
}
