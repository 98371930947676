@import '../../variables';

#services-hero,
#projects-hero {
    height: 80vh;
    position: relative;

    .background {
        position: absolute;
        z-index: -1;
        height: 100%;
        width: 100%;
        right: 0;
        top: 0;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .scrollbutton {
        margin-top: 4vw;
    }

    .text {
        display: flex;
        justify-content: space-between;
        align-items: end;
        height: 80%;
        padding: 0 $padding_main;

        @media (max-width: $mobile_version) {
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-end;
            height: 90%;
        }
    }
}
