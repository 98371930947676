@import '../../variables';

.projects-section {
    position: relative;

    .background {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    #projects {
        padding-top: 15vw;
        padding-bottom: 15vw;

        p {
            margin-top: 1vw;
            width: 55vw;
            @media (max-width: $mobile_version) {
                width: 100%;
            }
        }

        .more {
            margin-top: 3vw;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            float: right;
        }

        ul {
            margin-top: 3vw;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: stretch;
            height: 16vw;
            @media (max-width: $mobile_version) {
                height: 40vw;
            }

            li {
                width: 32.5%;
                @media (max-width: $mobile_version) {
                    width: 31.5%;
                }
                &:hover img {
                    opacity: 0.6;
                }
                p {
                    margin-top: 0.5vw;
                    width: 100%;
                    font-weight: 700;
                    color: $color_jet;
                }
                img {
                    cursor: pointer;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
