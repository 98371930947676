@import '../../variables';

#about-hero,
#about-details {
    .background {
        position: absolute;
        z-index: -1;
        height: 100%;
        width: 50vw;
        right: 0;
        top: 0;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    h2 {
        margin-top: 10vw;
        margin-bottom: 3vw;
    }

    p {
        margin-top: 1vw;
        width: 30vw;
        @media (max-width: $mobile_version) {
            width: 100%;
        }
    }

    .text {
        display: flex;
        justify-content: space-between;
        align-items: end;
        .left {
            width: 57%;
        }
    }
}

#about-hero {
    padding-top: 12vw;
    padding-bottom: 5vw;

    h2 {
        font-size: $text_size_big;
        line-height: $text_size_big * 1.4;
        letter-spacing: -1px;
        margin-top: 2vw;
        font-weight: 600;
        margin-bottom: 0;
        @media (max-width: $mobile_version) {
            font-size: $text_size_big_MOB;
            line-height: $text_size_big_MOB * 1.4;
        }
    }

    .scrollbutton {
        margin-top: 4vw;
    }

    .background {
        height: calc(100vh - 10vw);
        top: 10vw;
    }

    h1 {
        text-align: right;
        font-size: 7vw;
        line-height: 7vw;
    }

    .text {
        height: calc(100vh - 17vw);
    }

    @media (max-width: $mobile_version) {
        .background {
            height: calc(50vh - 10vw);
            top: 30vw;
            width: 80vw;
        }

        .btn {
            margin-bottom: 10vw;
        }

        h1 {
            font-size: 13vw;
            line-height: 14vw;
            text-align: left;
        }

        .text {
            flex-direction: column;
            justify-content: end;
            .left,
            .right {
                width: 100%;
            }
        }
    }
}

#about-details {
    h3 {
        margin-top: 2vw;
        font-size: $text_size_big;
        line-height: $text_size_big * 1.4;
        letter-spacing: -1px;
        font-weight: 600;
        @media (max-width: $mobile_version) {
            font-size: $text_size_big_MOB;
            line-height: $text_size_big_MOB * 1.4;
        }
    }

    position: relative;
    .text .left {
        padding-bottom: 5vw;
    }

    @media (max-width: $mobile_version) {
        .background {
            height: 25vw;
            width: 75vw;
        }

        .text .left {
            margin-top: 9vw;
            width: 100%;
        }
    }
}
