@import '../../variables';

.map-section {
    height: 30vh;
    position: relative;
    cursor: pointer;

    &.more .background {
        background-color: black;
    }

    .background {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;

        img {
            opacity: 0.7;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    &:hover .background {
        opacity: 0.3;
    }

    .text {
        display: flex;
        justify-content: center;
        width: 100%;
        align-items: center;
        height: 100%;
        z-index: 1;
        position: absolute;
    }

    h2 {
        font-size: 2.6vw;
        line-height: 4vw;
        letter-spacing: 1px;
        @media (max-width: $mobile_version) {
            font-size: 7vw;
            line-height: 10vw;
        }
        margin-top: 6vw;
        margin-bottom: 4vw;
        color: $color_light;
    }

    @media (max-width: $mobile_version) {
    }
}
