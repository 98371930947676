@font-face {
    font-family: 'Albula';
    src: url('../fonts/albula/Albula-ExtraLight.eot');
    src: local('Albula ExtraLight'), local('Albula-ExtraLight'), url('../fonts/albula/Albula-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/albula/Albula-ExtraLight.woff2') format('woff2'), url('../fonts/albula/Albula-ExtraLight.woff') format('woff'),
        url('../fonts/albula/Albula-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Albula';
    src: url('../fonts/albula/Albula-ExtraBold.eot');
    src: local('Albula ExtraBold'), local('Albula-ExtraBold'), url('../fonts/albula/Albula-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/albula/Albula-ExtraBold.woff2') format('woff2'), url('../fonts/albula/Albula-ExtraBold.woff') format('woff'),
        url('../fonts/albula/Albula-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Albula';
    src: url('../fonts/albula/Albula-Heavy.eot');
    src: local('Albula Heavy'), local('Albula-Heavy'), url('../fonts/albula/Albula-Heavy.eot?#iefix') format('embedded-opentype'),
        url('../fonts/albula/Albula-Heavy.woff2') format('woff2'), url('../fonts/albula/Albula-Heavy.woff') format('woff'),
        url('../fonts/albula/Albula-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Albula';
    src: url('../fonts/albula/Albula-Light.eot');
    src: local('Albula Light'), local('Albula-Light'), url('../fonts/albula/Albula-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/albula/Albula-Light.woff2') format('woff2'), url('../fonts/albula/Albula-Light.woff') format('woff'),
        url('../fonts/albula/Albula-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Albula';
    src: url('../fonts/albula/Albula-Bold.eot');
    src: local('Albula Bold'), local('Albula-Bold'), url('../fonts/albula/Albula-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/albula/Albula-Bold.woff2') format('woff2'), url('../fonts/albula/Albula-Bold.woff') format('woff'), url('../fonts/albula/Albula-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Albula';
    src: url('../fonts/albula/Albula-Regular.eot');
    src: local('Albula Regular'), local('Albula-Regular'), url('../fonts/albula/Albula-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/albula/Albula-Regular.woff2') format('woff2'), url('../fonts/albula/Albula-Regular.woff') format('woff'),
        url('../fonts/albula/Albula-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Albula';
    src: url('../fonts/albula/Albula-Medium.eot');
    src: local('Albula Medium'), local('Albula-Medium'), url('../fonts/albula/Albula-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/albula/Albula-Medium.woff2') format('woff2'), url('../fonts/albula/Albula-Medium.woff') format('woff'),
        url('../fonts/albula/Albula-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Albula';
    src: url('../fonts/albula/Albula-Thin.eot');
    src: local('Albula Thin'), local('Albula-Thin'), url('../fonts/albula/Albula-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/albula/Albula-Thin.woff2') format('woff2'), url('../fonts/albula/Albula-Thin.woff') format('woff'), url('../fonts/albula/Albula-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Albula';
    src: url('../fonts/albula/Albula-SemiBold.eot');
    src: local('Albula SemiBold'), local('Albula-SemiBold'), url('../fonts/albula/Albula-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/albula/Albula-SemiBold.woff2') format('woff2'), url('../fonts/albula/Albula-SemiBold.woff') format('woff'),
        url('../fonts/albula/Albula-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
