@import '../../variables';

#hero {
    padding-top: 12vw;

    @media (max-width: $mobile_version) {
        .text {
            margin-top: 40vh;
        }
    }

    p {
        margin-top: 2vw;
        width: 70vw;
        font-size: $text_size_med;
        line-height: $text_size_med * 1.4;
        font-weight: 400;
        @media (max-width: $mobile_version) {
            font-size: $text_size_med_MOB;
            line-height: $text_size_med_MOB * 1.4;
        }
    }

    .scrollbutton {
        margin-top: 10vw;
        display: flex;
        justify-content: space-between;
        padding-bottom: 5vw;
        @media (max-width: $mobile_version) {
            margin-top: 2vw;
            flex-direction: column-reverse;
        }
        p {
            margin-top: 0vw;
            width: auto;
            @media (max-width: $mobile_version) {
                margin-top: 10vw;
            }
        }
    }

    img {
        max-height: 3vh;
        margin-top: 0.5vh;
    }
}
