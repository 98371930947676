@import '../../variables';

#contact-hero {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: $mobile_version) {
        flex-direction: column;
    }

    .padding {
        height: calc(100vh - 9vw);
        padding-top: 7vw;

        @media (max-width: $mobile_version) {
            height: unset;
            padding-top: 45vw;
            padding-bottom: 30vw;
        }
    }

    h1 {
        font-size: 2.6vw;
        line-height: 4vw;
        @media (max-width: $mobile_version) {
            font-size: 7vw;
            line-height: 10vw;
        }
        margin-top: 6vw;
        margin-bottom: 4vw;
    }

    h2 {
        font-size: $text_size_med;
        line-height: $text_size_med * 1.4;
        letter-spacing: 0;
        font-weight: 600;
        @media (max-width: $mobile_version) {
            font-size: $text_size_med_MOB;
            line-height: $text_size_med_MOB * 1.4;
        }
    }

    #form {
        background-color: white;
        color: black;
        padding-left: 4vw;
        padding-right: 4vw;
        padding-bottom: 2vw;
        width: 27vw;
        overflow: auto;

        @media (max-width: $mobile_version) {
            padding-top: 10vw;
            width: 92vw;
        }

        &::-webkit-scrollbar {
            width: 1vw;
            background: #e5e5e5;
        }

        &::-webkit-scrollbar-thumb {
            background: $color_milk;
        }

        h3 {
            margin-top: 3vw;
        }

        .form-wrapper {
            margin-top: 2vw;
            @media (max-width: $mobile_version) {
                margin-top: 6vw;
            }
        }

        .input-group {
            display: table;
            width: 100%;
            margin-top: 1vw;

            p {
                font-weight: 700;
            }

            input,
            textarea {
                margin-top: 0.4vw;
            }

            &.message {
                color: rgb(56, 190, 56);
            }

            &.error {
                color: rgb(190, 56, 56);
            }
        }
    }

    #text {
        .detail {
            margin-bottom: 2vw;
            p {
                margin-top: 0.5vw;
                color: $color_jet;
            }
        }
    }

    @media (max-width: $mobile_version) {
        .btn {
            margin: 3vw 0;
        }
    }
}
