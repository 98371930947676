@import '../../variables';

.contact-section {
    position: relative;

    .background {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        background-color: #0c0c0c;
        @media (max-width: $mobile_version) {
            display: none;
        }

        img {
            opacity: 0.5;
            object-fit: cover;
            width: 50%;
            position: absolute;
            right: 0;
            height: 100%;
        }
    }

    #contact {
        padding-top: 15vw;
        padding-bottom: 10vw;
        @media (max-width: $mobile_version) {
            padding-top: 0;

            .text h2 {
                display: none;
            }
        }

        .background {
            display: none;
            position: relative;
            width: 100%;
            height: 40vw;
            @media (max-width: $mobile_version) {
                display: block;
            }
            img {
                opacity: 0.5;
                object-fit: cover;
                width: 100%;
                position: absolute;
                right: 0;
                height: 100%;
            }
            h2 {
                position: absolute;
                z-index: 1;
                top: 60%;
                width: 100%;
                left: $padding_main_MOB;
            }
        }

        p {
            margin-top: 1vw;
            width: 35vw;
            @media (max-width: $mobile_version) {
                width: 100%;
                margin-top: 5vw;
            }
        }

        .contact {
            margin-top: 2vw;
            @media (max-width: $mobile_version) {
                margin-top: 5vw;
            }
            h3 {
                font-size: $text_size_med;
                line-height: $text_size_med * 1.4;
                letter-spacing: 0;
                font-weight: 600;
                margin-top: 0.5vw;
                @media (max-width: $mobile_version) {
                    font-size: 3.5vw;
                    line-height: 6vw;
                }
                a {
                    display: table;
                }
                img {
                    width: 1vw;
                    padding-right: 1vw;
                    @media (max-width: $mobile_version) {
                        padding-right: 3vw;
                        width: 4vw;
                    }
                }
            }
        }
    }
}
