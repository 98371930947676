@import '../variables';

#header {
    @media (max-width: $mobile_version) {
        background-color: rgba(0, 0, 0, 0.8);
        height: 10vh;
    }

    @media (min-width: $mobile_version_plus) {
        &.scrolled {
            height: 5vw;
            background-color: rgba(0, 0, 0, 0.8);
        }

        &.contact {
            background-color: rgba(0, 0, 0, 0.8);
        }
    }

    z-index: 100;
    height: 7vw;
    width: 100vw;
    position: fixed;
    display: flex;
    background-color: rgba(0, 0, 0, 0);

    .padding {
        display: flex;
        flex: 1;
        flex-direction: row;
        position: relative;
    }

    .logo {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 1.6vw;
        @media (max-width: $mobile_version) {
            font-size: 6vw;
        }
    }

    .burger {
        z-index: 11;
        width: 10vh;
        height: 10vh;
        position: fixed;
        top: 0;
        right: 0;

        .button {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 4px;
            align-items: center;
            width: 10vh;
            height: 10vh;
        }

        .line {
            width: 2vh;
            height: 1px;
            background-color: white;
        }
    }

    @media (min-width: $mobile_version_plus) {
        .burger {
            display: none;
        }

        .pages {
            flex-grow: 1;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            ul {
                display: flex;
                gap: 3vw;
                float: right;
            }
        }
    }

    @media (max-width: $mobile_version) {
        .pages {
            position: fixed;
            left: 0;
            top: 0;
            width: 100vw;
            height: 100vh;
            background: black;
            z-index: 10;
            overflow: hidden;
            display: none;

            ul {
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 5vh;
                align-items: center;
                height: 100vh;

                p {
                    font-size: 7vw;
                    line-height: 6.8vw;
                }
            }

            &.open {
                display: block;
            }
        }
    }
}
