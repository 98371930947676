@import '../../variables';

.crew-section {
    #crew {
        padding-top: 8vw;
        padding-bottom: 8vw;
        @media (max-width: $mobile_version) {
            padding-top: 16vw;
            padding-bottom: 16vw;
        }

        h2 {
            text-align: center;
        }

        p {
            &.description {
                width: 30vw;
                margin-top: 1vw;
                @media (max-width: $mobile_version) {
                    width: unset;
                }
            }
            margin: 0 auto;
            text-align: center;
        }

        .crew {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: stretch;
            width: 50vw;
            margin: 0 auto;
            gap: 2vw;
            margin-top: 2vw;
            @media (max-width: $mobile_version) {
                flex-wrap: wrap;
                justify-content: center;
                width: unset;
            }

            .pro {
                width: 14vw;
                position: relative;
                margin-top: 1vw;
                text-align: center;
                @media (max-width: $mobile_version) {
                    width: 35vw;
                }

                p {
                    color: $color_jet;
                    font-weight: 600;
                    margin-top: 0.2vw;
                    &.name {
                        color: white;
                        font-weight: 700;
                        margin-top: 1.4vw;
                    }
                }
                .photo {
                    width: 100%;
                    height: 12vw;
                    position: relative;
                    overflow: hidden;
                    border-radius: 0.4vw;
                    @media (max-width: $mobile_version) {
                        height: 25vw;
                    }

                    img {
                        object-fit: cover;
                        width: 100%;
                        opacity: 0.9;
                        height: 100%;
                    }
                }
            }
        }
    }
}
